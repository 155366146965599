@font-face {
  font-family: 'Epilogue-Bold';
  src: url('../public/Fonts/Epilogue-Bold.ttf');
}
@font-face {
  font-family: 'Epilogue-Light';
  src: url('../public/Fonts/Epilogue-Light.ttf');
}
@font-face {
  font-family: 'Epilogue-Regular';
  src: url('../public/Fonts/Epilogue-Regular.ttf');
}
@font-face {
  font-family: 'WorkSans-Light';
  src: url('../public/Fonts/WorkSans-Light.ttf');
}
@font-face {
  font-family: 'WorkSans-Regular';
  src: url('../public/Fonts/WorkSans-Regular.ttf');
}
@font-face {
  font-family: 'WorkSans-Bold';
  src: url('../public/Fonts/WorkSans-Bold.ttf');
}
.navbar{
  background-color: transparent;
}
.nav-link{
  font-family: 'WorkSans-Regular';
  color: black !important;
  font-size: 18px;
  letter-spacing: 1px;
  margin: 0 20px;
}
.container{
  max-width: 85% !important;
}
section{
  padding: 80px 0;
}
.background-smoke{background-color: #c5c5c5;}
.background-white{background-color: white;}
.background-black{background-color: black !important;}
.background-aqua{background-color: #1daaac !important;}
a{color: inherit !important;}
h1{font-size: 80px !important;font-family: 'Epilogue-Bold';}
h2{font-size: 50px !important;font-family: 'Epilogue-Bold';}
p{font-family: 'WorkSans-Regular';
font-size: 17px;}
footer{
  background-color: black;
  padding: 30px 0 0;
}
footer ul{display: flex;justify-content: space-between;color: white;}
footer li{list-style: none;}
footer .linkedin-link{color: white !important; position: relative; font-family: 'WorkSans-Light';}
footer .linkedin-link:hover{text-decoration: none;}
footer .linkedin-link::after{
  position: absolute;
  content: '';
  height: 1px;
  width: 66%;
  left: 1%;
  right: 1%;
  background-color: white;
  bottom: -40%;
}
footer p, footer a{font-size: 14px !important;}
@media (max-width:1800px){
  h1{font-size: 60px !important;}
  h2{font-size: 45px !important;}
  h3{font-size: 25px !important;}
  p{font-size: 16px;}
}
@media (max-width:1500px){
  h1{font-size: 50px !important;}
}
@media (max-width:1280px){}
@media (max-width:1200px){
  .container-fluid{width: 85% !important;padding: 0px !important;}
}
@media (max-width:960px){
  h1{font-size: 45px !important;}
  h2{font-size: 35px !important;}
  h3{font-size: 20px !important;}
  .navbar-light .navbar-toggler{border: none;}
  .nav-link{
    color: white !important;
    font-family: 'Epilogue-Bold';
    font-size: 40px;
  }
  .navbar-collapse {
    position: fixed;
    top: 0px;
    left: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    width: 75%;
    height: 100%;
  }

.navbar-collapse.collapsing {
    left: -75%;
    transition: height 0s ease;
  }

.navbar-collapse.show {
    left: 0;
    transition: left 300ms ease-in-out;
    background: #000000bf;
    width: 100vw;
    padding: 30px 15px;
  }

.navbar-toggler.collapsed ~ .navbar-collapse {
    transition: left 500ms ease-in-out;
  }
.navbar-nav .logo{
    width: 30%;
    object-fit: contain;
  }
.navbar-collapse .navbar-toggler{
    position: absolute;
    right: 3%;
    color: white !important;
    font-size: 30px;
  }
  .mobile-reverse{flex-direction: column-reverse;}
  footer ul{flex-direction: column;padding: 0px;}
  .border-bottom-mobile{border-bottom: 1px solid white;}
  footer{padding: 30px 0;}
  footer .linkedin-link::after{display: none;}
}
@media (max-width:480px){
  .container {max-width: 90% !important;}
  h1{font-size: 35px !important;}
  h2{font-size: 25px !important;}
  h3{font-size: 16px !important;}
  .navbar-brand img{width: 100px;}
  p{font-size: 14px;}
  footer .logo-main{width: 100px;}
  .nav-link{font-size: 30px;}
  section {padding: 50px 0;}
}