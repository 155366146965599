.works-section h2{color: black;padding-top: 100px;}
.works-section h5{font-family: 'Epilogue-Bold'; color: black;}
.nav-tabs .nav-link{
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    background: transparent;
}
.nav-tabs {
    justify-content: center;
    margin: 30px 0;
}
.nav-tabs .nav-link {
    color: #999999 !important;
    font-size: 18px;
    padding: 0 10px;
    text-transform: uppercase;
    font-family: 'WorkSans-Regular';
    line-height: 3;
}
.nav-tabs .nav-link.active {
    border-bottom: 2px solid black !important;
    font-weight: bold;
    color: black !important;
}
.worktype{
    margin-bottom: 50px;
}
.clickable-img:hover{
    cursor: pointer;
    opacity: 0.8;
    transition: 0.6s ease;
}
.banner-full{
    width: 100%;
    max-height: 800px;
    object-fit: cover;
}
.single-work .showmore button{
    color: white;
    padding: 15px 0;
    width: 15%;
    background-color: black;
    border-radius: 30px;
}
.nextbrand-button{
    font-family: 'WorkSans-Regular';
    color: white !important;
    font-size: 23px;
    background-color: #1daaac;
    border: 1px solid #1daaac;
    border-radius: 0px;
    width: 30%;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
}
@media (max-width:960px){
    .nextbrand-button {
        font-size: 20px;
        width: 100%;
        padding: 10px 0;
      }
}
