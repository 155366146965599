.video-header{
    width: 100%;
    height: 70vh;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    bottom: 0;
    z-index: -1;
}
.video-overlay {
    position: relative;
    z-index: 0;
    height: 70vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background: linear-gradient(white, transparent);
}
.big{
    color: white;
    font-size: 250px !important;
}
.ml-15{margin-left: 15%;}
.contact-header{position: relative;}
.contact-header::after{
    position: absolute;
    bottom: 0%;
    content: "";
    height: 1px;
    background-color: #c5c5c5;
    width: 85%;
    right: 8%;
}
.contact-header p{
    color: white;
}
.contact-header p{width: 40%;margin: 0 auto 0 15%;}
.contact-header .btn, .enquiry-form .btn{
    color: white !important;
    background-color: #1daaac;
    border: 1px solid #1daaac;
    border-radius: 0px;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    margin: 30px auto 0 0;
    font-size: 20px;
    justify-content: space-between;
}
.contact-header .btn:hover, .enquiry-form .btn:hover{
  background-color: #094e4f;
  border: 1px solid #094e4f;
}
.enquiry-form .btn:disabled{
  background-color: #094e4f;
  border: 1px solid #094e4f;
  opacity: 0.4;
}
.contact-header .btn{padding: 15px 30px;width: 30%;font-family: 'Epilogue-Bold';}
.enquiry-form .btn{width: 20%; padding: 15px 30px;}
.form-control{
    background: transparent;
    border-radius: 0px;
    border: 1px solid white;
    padding: 30px;
    color: white;
}
.form-check-label, .col-form-label{color: white;font-family: 'WorkSans-Light';}
.form-check-label{padding-bottom:10px; margin-top: 0;margin-left: 15px;}
input[type='radio'] {
    -webkit-appearance:none;
    width:15px;
    height:15px;
    border:1px solid white;
    border-radius:50%;
    outline:none;
    margin-right: 5px;
  }
  
  input[type='radio']:before {
    content:'';
    display:block;
    width:100%;
    height:100%;
    margin: 0 auto;    
    border-radius:50%;    
  }
  input[type='radio']:checked:before {
    background:white;
  }
  input[type="checkbox"]:checked {
  background: white;
  color: red;
}

input[type="checkbox"] {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  background: transparent;
  height: 15px;
  width: 15px;
  border: 1px solid white;
  color: white;
}

input[type="checkbox"]:after {
    content: ' ';
    position: relative;
    left: 36%;
    top: 0%;
    width: 35%;
    height: 80%;
    border: solid black;
    border-width: 0 2px 2px 0;
    transform: rotate(50deg);
    display: none;
}

input[type="checkbox"]:checked:after {
  display: block;
}
@-webkit-keyframes loader {
	0% {
		width: 0
	}
	20% {
		width: 10%
	}
	25% {
		width: 24%
	}
	43% {
		width: 41%
	}
	56% {
		width: 50%
	}
	66% {
		width: 52%
	}
	71% {
		width: 60%
	}
	75% {
		width: 76%
	}
	94% {
		width: 86%
	}
	100% {
		width: 100%
	}
}

@keyframes loader {
	0% {
		width: 0
	}
	20% {
		width: 10%
	}
	25% {
		width: 24%
	}
	43% {
		width: 41%
	}
	56% {
		width: 50%
	}
	66% {
		width: 52%
	}
	71% {
		width: 60%
	}
	75% {
		width: 76%
	}
	94% {
		width: 86%
	}
	100% {
		width: 100%
	}
}

.progress2-bar {
	width: 95%;
	max-width: 95%;
	left: 50%;
	width: 100%;
	max-width: 100%;
	border-radius: 0;
}

.progress2 {
	margin-bottom: 0!important
}

.bar {
	margin-top: 25px!important;
	margin-bottom: 0!important
}

.progress2-bar {
	border-radius: 0;
	overflow: hidden;
	width: 100%;
	padding: 0 15px;
	display: none;
}

.progress2-bar span {
	display: block
}

.bar {
	background: 0 0
}

.progress2 {
	-webkit-animation: loader 8s ease infinite;
	animation: loader 8s ease infinite;
  background: #1daaac;
  color: #1daaac;
  padding: 5px;
	padding: 5px;
	width: 0;
	margin-top: 25px;
	margin-bottom: 25px
}

.progress2-bar {
	position: relative;
	-webkit-transform: translate3d(-50%, -50%, 0);
	transform: translate3d(-50%, -50%, 0);
	background-color: transparent !important;
}
p{color: white;}
@media (max-width:1800px){
  .big {font-size: 200px !important;}
  .contact-header .btn{width: 35%;}
}
@media (max-width:1500px){
  .big {font-size: 150px !important;}
  .contact-header p{width: 45%;}
}
@media (max-width:1280px){
  .contact-header .btn, .enquiry-form .btn{font-size: 18px;}
  .form-check-label, .col-form-label{font-size: 16px;}
}
@media (max-width:1200px){
  .contact-header p {
    width: 65%;
}
.contact-header .btn {
  width: 45%;
}
.enquiry-form .btn{width: 30%;}
}
@media (max-width:960px){
  .contact-header .btn {
    width: 70%;
}
}
@media (max-width:480px){
  .big {
    font-size: 120px !important;
}
.contact-header p {
  width: 100%;
  margin: 0;
}
.contact-header .btn, .enquiry-form .btn {
  font-size: 15px;
  width: 100%;
}
.form-check-label, .col-form-label {
  font-size: 14px;
}
}