.top-content{margin-top: 150px;}
.services-slider .card{
    text-align: center;
    color: white;
    height: 550px;
    justify-content: center;
    border-radius: 0px;
}
.services-slider .card h3{
    font-size: 35px;
    font-family: 'Epilogue-Bold';
}
.services-slider .slanted{
    font-style: italic;
    font-family: 'WorkSans-Light';
}
.services-slider p{
    font-family: 'WorkSans-Light';
    margin-bottom: 0px;
    width: 55%;
    margin: 0 auto;
}
.services-slider .price{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: center;
}
.services-slider .extras{padding: 50px 0;}
.services-slider .price::after, .services-slider .price::before{
    position: absolute;
    content: "";
    height: 1px;
    background-color: white;
    width: 65%;
    text-align: center;
}
.services-slider .price::after{bottom: -30%;}
.services-slider .price::before{top: -30%;}
sup, sub {
    font-size: 14px !important;
    font-family: 'WorkSans-Light';
}
sup{top: -1.1em;}
.retainer-area h2{color: black;}
.retainer-area {padding-bottom: 0px;}
.materials-content{column-count: 3;}
.materials-area .small{
    margin-bottom: 10px;
}
.materials-area .tag::after{display: none;}
@media (max-width:1800px){

}
@media (max-width:1500px){}
@media (max-width:1280px){
    .services-slider .card h3{font-size: 30px;}
}
@media (max-width:1200px){}
@media (max-width:960px){
    .materials-content {
        column-count: 2;
    }
    .owl-carousel.services-slider .owl-dots{
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }
    .owl-carousel.services-slider button.owl-dot{
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #88d3d3;
        margin: 0 5px;
        opacity: 0.5;
    }
    .owl-carousel.services-slider button.owl-dot.active{opacity: 1.0;}
    .owl-carousel.services-slider .owl-nav button.owl-prev, .owl-carousel.services-slider .owl-nav button.owl-next{
        color: white;
        font-size: 23px;
        width: 50px;
        height: 50px;
        background: #88d3d3;
        border-radius: 50%;
        position: absolute;
        top: 46%;
    }
    .owl-carousel.services-slider .owl-nav button.owl-prev{left: -3%;}
    .owl-carousel.services-slider .owl-nav button.owl-next{right: -3%;}
}
@media (max-width:480px){
    .top-content {
        margin-top: 80px;
    }
    .services-slider .card{height: auto;padding: 50px 0 60px 0;}
    .services-slider p{width: 80%;}
    .services-slider .extras{padding: 30px 0;}
    .services-slider .card h3 {
        font-size: 25px;
    }
    .owl-carousel.services-slider .owl-nav button.owl-prev {
        left: -8%;
    }
    .owl-carousel.services-slider .owl-nav button.owl-next{right: -8%;}
    .materials-content {
        column-count: unset;
        text-align: center;
    }
    .materials-content h5{font-size: 16px;}
    .viewmore-btn{
        padding: 10px 40px;
        background: black;
        color: white !important;
        border-radius: 25px;
        text-decoration: none;
        letter-spacing: 1px;
    }
}