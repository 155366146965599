.home-banner{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c5c5c5;
}
.home-banner-copy{margin-top: 10%;}
.home-banner h1{
    
    line-height: 1;
    width: 80%;
    color: black;
}
.home-banner p{
    
    color: black;
    font-weight: 600;
    font-size: 23px;
    width: 80%;
}
.home-banner a{
    font-family: 'WorkSans-Regular';
    color: white !important;
    font-size: 23px;
    background-color: #1daaac;
    border: 1px solid #1daaac;
    border-radius: 0px;
    width: 40%;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
}
.home-banner a:hover{
    text-decoration: none;
    background-color: #094e4f;
    border: 1px solid #094e4f;
}
.long-arrow{
    margin-left: 5%;
    font-size: 30px;
}
.work-area img{
    object-fit: cover;
   width: 100%;
   max-height: 100%;
   padding: 10px 0;
}
.work-area img:hover{
    cursor: pointer;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out; 
}
.work-area .big-frame img.big{
    height: 100%;
}
.showmore button{
    font-family: 'WorkSans-Regular';
    color: #7c7c7c;
    font-size: 18px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0px;
    padding: 15px 30px;
    text-transform: uppercase;
}
.black-copy-area{
    background-color: #252525;
    position: relative;
}
.black-copy-area h2{
    color: #1daaac;
    width: 60%;
}
.black-copy-area p{
    color: white;
    margin: 30px 0;
}
.black-copy-area lottie-player{position: absolute;width: 100%;top: 0px;}
@media (max-width:1800px){
    .home-banner p, .home-banner a{font-size: 20px;}
    .black-copy-area h2{width: 70%;}
}
@media (max-width:1500px){
    .home-banner p{width: 100%;}
    .home-banner p, .home-banner a {font-size: 17px;}
    .black-copy-area h2 {width: 80%;}
    .long-arrow {font-size: 25px;}
}
@media (max-width:1280px){}
@media (max-width:1200px){}
@media (max-width:960px){
    .black-copy-area h2 {
        width: 100%;
    }
    .black-copy-area lottie-player{display: none;}
}
@media (max-width:480px){
    .home-banner{align-items: unset;padding-top: 80px;}
    .home-banner p, .home-banner a {
        font-size: 16px;
    }
    .home-banner a{width: 80%;padding: 10px 0}
    .black-copy-area p{margin: 20px 0;}
    .home-banner lottie-player{
        position: absolute;
        bottom: -20%;
    }
}