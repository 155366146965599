.black-copy-area.about{margin-top: 80px;}
.gray-copy-area h2, .process-area h2{color: black;}
.tag{
    font-family: 'WorkSans-Bold';
    color: black;
    position: relative;
    margin-bottom: 0px;
    height: unset !important;
}
.tag::after{
    content: "";
    height: 1px;
    background-color: black;
    width: 100px;
    top: 55%;
    position: absolute;
    margin-left: 30px;
}
.gray-copy-area h3, .process-area h3, .division-child h3{
    font-family: 'Epilogue-Bold';
    color: black;
    font-size: 28px;
    margin-bottom: 0px;
    line-height: 1.2;
}
.content-copy{
    width: 90%;
    height: 180px;
}
.content-copy p, .process-area p{
    color: black;
    margin-bottom: 0px;
    line-height: 1.5;
}
.process-area .card{
    border: none;
    border-radius: 0px;
    padding: 100px 0px 30px 30px;
    box-shadow: 6px 2px 25px #9393937d;
    margin: 20px;
}
.process-area .card-copy{
    width: 95%;
}
.process-area .tag::after{
    top: -30%;
    width: 100%;
    left: 0%;
    right: 0%;
    margin-left: 0px;
}
.process-area h3{height: 100px;}
.process-area .card-copy p{height: 170px;}
.process-slider .owl-item{width: 36% !important;}
.process-slider .item:hover {
    transition: .8s ease;
    -webkit-transform: translate(100px, 0%);
    -ms-transform: translate(100px, 0%);
    transform: translate(100px, 0%);
    z-index: 10;
    cursor: pointer;
}
.process-slider .item-inner-wrapper {position: relative;}
.process-slider .owl-item:first-child{z-index: 3;margin-left: 0px !important;}
.process-slider .owl-item:nth-child(2){z-index: 2; margin-left: -100px;}
.process-slider .owl-item:nth-child(3){z-index: 1; margin-left: -100px;}
.division-child{
    display: flex;
    flex-direction: row;
    align-items: top;
    position: relative;
    margin: 40px 0;
}
.division-child::after{
    position: absolute;
    content: "";
    height: 2px;
    background-color: black;
    width: 100%;
    bottom: -30%;
}
.division-child:hover{
    background-color: #83e9eb87;
}
.division-child p{
    margin-bottom: 0px;
    line-height: 1;
    font-family: 'Epilogue-Regular';
    font-size: 14px;
    margin-right: 10px;
    color: black;
}
    @media (max-width:1800px){
    .gray-copy-area h3, .process-area h3, .division-child h3{font-size: 25px;}
  }
  @media (max-width:1500px){

  }
  @media (max-width:1280px){
    .gray-copy-area h3, .process-area h3, .division-child h3 {
        font-size: 23px;
    }    
  }
  @media (max-width:1200px){
    .black-copy-area.about h2 {
        width: 100%;
    }
    .process-slider .owl-item{width: 420.2px !important;}
    .process-slider .owl-item:nth-child(2), .process-slider .owl-item:nth-child(3){margin-left: 0px;}
    .process-area .card {
        border: 1px solid black;
        padding: 50px 0px 20px 20px;
        box-shadow: 2px 2px 5px 3px #dbdbdb;
        margin: 1px;
    }
    .process-area h3 {
        height: 80px;
    }
    .process-slider .item:hover {
        transition: none;
        transform: none;
        z-index: 1;
        cursor: none;
    }
    .process-slider .item:hover {
        transition: none;
        transform: none;
        z-index: 1;
        cursor: none;
    }
    .owl-carousel.process-slider .owl-dots{
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }
    .owl-carousel.process-slider button.owl-dot{
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #51b4b6;
        margin: 0 5px;
        opacity: 0.5;
    }
    .owl-carousel.process-slider button.owl-dot.active{opacity: 1.0;}
    .owl-carousel.process-slider .owl-nav button.owl-prev, .owl-carousel.process-slider .owl-nav button.owl-next{
        color: white;
        font-size: 23px;
        width: 50px;
        height: 50px;
        background: #51b4b6;
        border-radius: 50%;
        position: absolute;
        top: 46%;
    }
    .owl-carousel.process-slider .owl-nav button.owl-prev{left: -3%;}
    .owl-carousel.process-slider .owl-nav button.owl-next{right: -3%;}
  }
  @media (max-width:960px){
    .process-slider .owl-item{width: 311.4px !important;}
    .content-copy {width: 100%;height: auto;} 
    .process-area h3 {height: 120px;}
    .process-area .card-copy p {height: 230px;}
    .process-area .card{padding: 30px 0 20px 20px;}
  }
  @media (max-width:480px){
    .process-slider .owl-item{width: 318.913px !important;}
    .process-area h3, .process-area .card-copy p{height: auto;}
    .process-area .card-copy p{margin-bottom: 30px;}
    .process-area .card-copy .tag{margin-bottom: 0px;}
    .division-child{margin: 30px 0;}
    .black-copy-area.about {margin-top: 60px;}
  }